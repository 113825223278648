// 
// page: portfolio
//

.section-portfolio {
  margin-top: 65px;
  .portfolio-nav-tabs {
    padding-top: 55px;
    padding-bottom: 45px;
    .nav {
      display: block;
      .nav-item {
        display: block;
        .nav-link {
          position: relative;
          padding: 15px 0;
          padding-left: 70px;
          font-size: 20px;
          font-weight: $font-weight-regular;
          color: #575756;
          &:before {
            content: '';
            z-index: -1;
            background-color: $primary;
            height: 50px;
            width: 50px;
            left: -60px;
            top: 50%;
            margin-top: -25px;
            position: absolute;
            border-radius: 100%;
            transition: all 0.5s;
          }
          i.icon {
            color: $primary;
            position: absolute;
            right: 20px;
            top: 50%;
            font-size: 10px;
            transition: all 0.35s;
            transform: translateY(-50%);
          }
          &[aria-expanded=true] {
            i.icon {
              transform: translateY(-50%) rotate(180deg);
            }
          }
          &.active, &:hover, &[aria-expanded=true] {
            color: $primary;
            &:before {
              left: -25px;
            }

          }
        }
      }
    }
  }
  .portfolio-tab-content {
    padding-top: 55px;
    padding-bottom: 45px;
  }
  .portfolio-inside-nav-tabs {
    .nav {
      display: block;
      .nav-item {
        display: block;
        .nav-link {
          position: relative;
          padding: 15px 0;
          padding-left: 50px;
          font-size: 17px;
          font-weight: $font-weight-regular;
          color: #575756;
          &.active, &:hover {
            color: $primary;
          }
        }
      }
    }
    &.collapse {
      .nav {
        .nav-item {
          .nav-link {
            padding-left: 70px;
          }
        }
      }
    }
  }
  .portfolio-inside-tab-content {
    .tab-pane {
      text-align: right;
    }
    .redirect {
      display: block;
      background-color: transparent;
      transition: all .3s;
      &:hover {
        background-color: $primary;
        /*.image-holder-bg {
          mix-blend-mode: multiply;
        }*/
      }
    }
    .redirect-btn {
      margin-top: 20px;
      font-size: 17px;
      font-weight: $font-weight-regular;
      color: $primary;
      display: inline-flex;
      align-items: center;
  
      .icon {
        margin-left: 5px;
        font-size: 9px;
        transition: margin-left .4s;
      }
  
      &:hover {
        .icon {
          margin-left: 8px;
        }
      }
    }
  }
  .left-border {
    position: relative;
    &:before {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 1px;
      background-color: $primary;
      content: '';
    }

  }
}

.section-portfolio-single {
  margin-top: 65px;
  
  h1, .h1 {
    font-size: 20px;
    font-weight: $font-weight-bold;
    margin-bottom: 0;
    color: #575756;
  }
  
  h3, .h3 {
    font-size: 20px;
    font-weight: $font-weight-regular;
    color: #575756;
    margin-bottom: 30px;
  }
  
  p {
    color: #575756;
    line-height: 24px;
    margin-bottom: 15px;
  }
  .portfolio-back {
    margin-bottom: 20px;
    position: relative;
    display: block;
    font-size: 17px;
    font-weight: $font-weight-regular;
    color: $primary;
  
    .icon {
      margin-right: 5px;
      font-size: 9px;
      transition: margin-right .4s;
    }
  
    &:hover {
      .icon {
        margin-right: 8px;
      }
    }
  }
  .image-holder-full {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  
  .builder-image {
    margin-top: 45px;
    &.shadow-bottom {
      box-shadow: 5px 8.66px 29px 0px rgba(0, 0, 0, 0.47);
    }
  }
}

@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(lg) {
}

@include media-breakpoint-down(md) {
  .section-portfolio {
    margin-bottom: 0;
    .portfolio-nav-tabs {
      padding-top: 30px;
      padding-bottom: 30px;
      .nav .nav-item .nav-link {
        padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 50px;
      }
    }
  }
  .section-portfolio-single {
    .image-holder-full {
      position: relative;
      padding-bottom: 43%;
      margin-top: 45px;
    }
    .portfolio-back {
    
    }
  }
}

@include media-breakpoint-down(sm) {

}


