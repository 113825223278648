// 
// layout: footer
//

.footer {
  margin-top: 200px;
  position: relative;
  overflow: hidden;

  &:before {
    position: absolute;
    content: '';
    width: 100vw;
    height: 100vh;
    background-color: #707070;
    top: 95px;
    left: 0;
    z-index: -1;
  }

  h2, .h2 {
    font-size: 87px;
    line-height: 95px;
    font-weight: $font-weight-semi-bold;
    margin-bottom: 175px;
    color: $white;

    span {
      color: $primary;
      font-weight: $font-weight-regular;
    }
  }

  .footer-img {
    display: flex;
    justify-content: center;
    margin-bottom: 90px;
  }

  .footer-text {
    padding-top: 25px;
    padding-bottom: 50px;
    text-align: center;
    display: flex;
    justify-content: center;

    p {
      font-weight: $font-weight-regular;
      color: #fff;
      margin: 0 10px;
      a {
        color: #fff;
      }
    }
  }
}

.Phone {
  position: relative;
  display: block;
  margin: 0;
  width: 128px;
  height: 128px;
  font-size: 25vmin;
  background-color: $primary;
  border-radius: 100%;
  box-shadow: 0 0 0 0em rgba(#3498db, 0),
  0em 0.05em 0.1em rgba(#000000, 0.2);
  transform: translate3d(0, 0, 0) scale(1);
}

.Phone::before,
.Phone::after {
  position: absolute;
  content: "";
}

.Phone::before {
  top: 50%;
  left: 50%;
  width: 128px;
  height: 128px;
  background-color: rgba(#fff, 0.1);
  border-radius: 100%;
  opacity: 1;
  transform: translate(-50%, -50%) translate3d(0, 0, 0) scale(0);
}

.Phone::after {
  top: 50%;
  left: 50%;
  width: calc(128px * 0.75);
  height: calc(128px * 0.75);
  background: url("../img/svg/logo.svg");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  transform: translate(-50%, -50%) translate3d(0, 0, 0);
}

.Phone {
  animation: phone-outer 3000ms infinite;
  
  &::before {
    animation: phone-inner 3000ms infinite;
  }
  
  &::after {
    animation: phone-icon 3000ms infinite;
  }
}

@keyframes phone-outer {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0em rgba($primary, 0),
    0em 0.05em 0.1em rgba(#000000, 0.2);
  }
  33.3333% {
    transform: translate3d(0, 0, 0) scale(1.1);
    box-shadow: 0 0 0 0em rgba($primary, 0.3),
    0em 0.05em 0.1em rgba(#000000, 0.5);
  }
  66.6666% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0.5em rgba($primary, 0),
    0em 0.05em 0.1em rgba(#000000, 0.2);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0em rgba($primary, 0),
    0em 0.05em 0.1em rgba(#000000, 0.2);
  }
}

@keyframes phone-inner {
  0% {
    opacity: 1;
    transform: translate(-50%, -50%) translate3d(0, 0, 0) scale(0);
  }
  33.3333% {
    opacity: 1;
    transform: translate(-50%, -50%) translate3d(0, 0, 0) scale(0.9);
  }
  66.6666% {
    opacity: 0;
    transform: translate(-50%, -50%) translate3d(0, 0, 0) scale(0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -50%) translate3d(0, 0, 0) scale(0);
  }
}


@keyframes phone-icon {
  0% {
    transform: translate(-50%, -50%) translate3d(0em, 0, 0);
  }
  2% {
    transform: translate(-50%, -50%) translate3d(0.01em, 0, 0);
  }
  4% {
    transform: translate(-50%, -50%) translate3d(-0.01em, 0, 0);
  }
  6% {
    transform: translate(-50%, -50%) translate3d(0.01em, 0, 0);
  }
  8% {
    transform: translate(-50%, -50%) translate3d(-0.01em, 0, 0);
  }
  10% {
    transform: translate(-50%, -50%) translate3d(0.01em, 0, 0);
  }
  12% {
    transform: translate(-50%, -50%) translate3d(-0.01em, 0, 0);
  }
  14% {
    transform: translate(-50%, -50%) translate3d(0.01em, 0, 0);
  }
  16% {
    transform: translate(-50%, -50%) translate3d(-0.01em, 0, 0);
  }
  18% {
    transform: translate(-50%, -50%) translate3d(0.01em, 0, 0);
  }
  20% {
    transform: translate(-50%, -50%) translate3d(-0.01em, 0, 0);
  }
  22% {
    transform: translate(-50%, -50%) translate3d(0.01em, 0, 0);
  }
  24% {
    transform: translate(-50%, -50%) translate3d(-0.01em, 0, 0);
  }
  26% {
    transform: translate(-50%, -50%) translate3d(0.01em, 0, 0);
  }
  28% {
    transform: translate(-50%, -50%) translate3d(-0.01em, 0, 0);
  }
  30% {
    transform: translate(-50%, -50%) translate3d(0.01em, 0, 0);
  }
  32% {
    transform: translate(-50%, -50%) translate3d(-0.01em, 0, 0);
  }
  34% {
    transform: translate(-50%, -50%) translate3d(0.01em, 0, 0);
  }
  36% {
    transform: translate(-50%, -50%) translate3d(-0.01em, 0, 0);
  }
  38% {
    transform: translate(-50%, -50%) translate3d(0.01em, 0, 0);
  }
  40% {
    transform: translate(-50%, -50%) translate3d(-0.01em, 0, 0);
  }
  42% {
    transform: translate(-50%, -50%) translate3d(0.01em, 0, 0);
  }
  44% {
    transform: translate(-50%, -50%) translate3d(-0.01em, 0, 0);
  }
  46% {
    transform: translate(-50%, -50%) translate3d(0em, 0, 0);
  }
}


@include media-breakpoint-down(xl) {
  .footer {
  }

}

@include media-breakpoint-down(lg) {
  .footer {
    margin-top: 150px;
    h2, .h2 {
      margin-bottom: 100px;
    }
  }
}

@include media-breakpoint-down(sm) {
  .footer {
    margin-top: 130px;
    .footer-text {
      flex-direction: column;
      p {
        margin-bottom: 5px;
      }
    }
  }
}

@include media-breakpoint-down(xs) {
  .footer {
    min-height: 488px;
    margin-top: calc(100vh - 488px);
    h2, .h2 {
      margin-bottom: 0;
    }

    .footer-img {
      position: absolute;
      top: 40%;
      left: 50%;
      width: 100%;
      margin-bottom: 0;
      transform: translate(-50%, -50%);
    }
    .footer-text {
      position: absolute;
      bottom: 0;
      width: 100%;
    }

    .footer-brand {
      margin-top: 20px;
    }

  }
}
