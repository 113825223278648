// 
// layout: offcanvas
//
.offcanvas {
    position: fixed;
    left: 50%;
    right: 0;
    top: 0;
    z-index: 9991;
    transform: translate3d(275px, -275px, 0);
    transition: all .4s;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    padding-right: 35px;
    padding-top: 30px;

    &:before {
        content: '';
        width: 550px;
        height: 550px;
        border-radius: 100%;
        background-color: $primary;
        position: absolute;
        top: -275px;
        right: -275px;
        z-index: -1;
    }
    
    ul.offcanvas-menu{
        z-index: 9991;
        padding: 0;
        margin: 0;
        > li a{
            position: relative;
            font-size: 18px;
            display: block;
            font-weight: $font-weight-regular;
            color: $white;
            margin-bottom: 15px;
            &:hover{
                color: $black;
            }

            &:before {
                content: '';
                width: 5px;
                height: 5px;
                background-color: $white;
                position: absolute;
                left: -27px;
                top: 50%;
                border-radius: 100%;
                transform: translateY(-50%);
            }
        }
    }
}

.offcanvas.active{
    transform: translate3d(0, 0, 0);
}



