// 
// Modal
// 

.modal-content{
    background-color: $white;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

.modal-content, .modal-footer, .modal-content{
    border-radius: 0px;
}

.modal-header, .modal-footer{
    border:0px;
}

button.close{
    color: $black;
}

@include media-breakpoint-down(sm) {
}