//
// Bredcrumbs
//
.breadcrumb {
    position: relative;
    z-index: 4;
    background-color: transparent;
    border-radius: 0px;
    margin: 0;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px;

    &-item,
    &-item a {
        font-family: inherit;
        font-size: 13px;
        color: #878686;
        transition: all 0.3s;
        font-weight: bold;
        text-transform: uppercase;
    }
    &-item a:hover {
        color: $text-color;
    }
    &-item+&-item{
        padding: 0;
    }
    &-item+&-item::before {
        padding-right: 6px;
        padding-left: 6px;
        content: "-";
        color: $text-color;
    }
    &-item.active {
        color: $text-color;
    }
}

@include media-breakpoint-down(md) {
    .breadcrumb{
        display: none;
    }
}

