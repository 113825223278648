// 
// Buttons
// 
.btn:hover,
.btn:focus,
.btn:active {
    box-shadow: none !important;
}

button {
    cursor: pointer;
}

.btn {
    position: relative;
    font-size: 13px;
    font-weight: bold;
    padding: 15px 40px;
    color: $text-color;
    border:1px solid $gray-300;
    outline: 0px;
    box-shadow: 0px;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    text-transform: uppercase;
    background-color: transparent;
    border-radius: 0px;
    text-align: center;
    overflow: hidden;
    z-index: 20;
    &:before{
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        max-width: 0%;
        background-color: $primary;
        transition: all .4s cubic-bezier(.365,.84,.44,1);
        z-index: -1;

    }

    &:active:not(:disabled):not(.disabled),
    &:hover:not(:disabled):not(.disabled),
    &:focus:not(:disabled):not(.disabled),
    &:active:not(:disabled):not(.disabled) {
        outline: none;
        color: $white;
        &:before{
            max-width: 100%;
        }
    }
}
.btn-white {
    border: none;
    color: $white;
}
@include media-breakpoint-down(xl) {}
