// 
// page: home
//
.page-home {

}


.loading {
	z-index: 9999;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0;
	top: 0;

	&.loaded {
		.dot {
			height: 4px;
			width: 4px;
		}
	}

	.dot {
		position: absolute;
		height: 8000px;
		width: 8000px;
		background-color: $primary;
		border-radius: 100%;
		transition: all 2.5s 1.5s;
		backface-visibility: hidden;
		transform: translate(-50%, -50%);
	}
}

.section-hero {
	height: 100vh;
	position: relative;
	
	.container {
		width: 100%;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		text-align: right;

		h1, .h1 {
			font-weight: $font-weight-semi-bold;
			margin-bottom: 15px;
			font-size: 100px;
			color: #58595B;
		}
	}

	.scroll-down {
		position: absolute;
		bottom: 40px;
		left: 50%;
		transform: translateX(-50%);
		height: 25px;
		width: 30px;
		animation: anim-bounce-header-slider .85s ease-in-out infinite alternate;
		display: block;

		&:before, &:after {
			content: '';
			position: absolute;
			top: 50%;
			left: 50%;
			width: 15px;
			height: 1px;
			z-index: 5;
			background-color: $primary;
			transform: translate(-50%, -50%);
		}

		&:before {
			left: calc(50% - 5px);
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&:after {
			left: calc(50% + 5px);
			transform: translate(-50%, -50%) rotate(-45deg);
		}
	}
}

@keyframes anim-bounce-header-slider {
	from {
		bottom: 30px;
	}
	to {
		bottom: 40px;
	}
}

.section-360 {
	margin-bottom: 150px;
	.section-title {
		margin-bottom: 130px;
	}
	
	.adam,
	.mona,
	.cotton {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		img {
			
			max-width: none;
		}
	}
	
	.text {
		margin-top: 25px;
		text-align: center;

		h5 {
			color: $primary;
			margin-bottom: 5px;
			font-weight: $font-weight-semi-bold;
			line-height: 26px;
		}

		p {
			color: #333333;
			font-size: 20px;
			line-height: 26px;
			opacity: 0;
		}
	}
	
	
	.circle {
		--width: 20vw;
		--height: 20vw;
		width: var(--width);
		height: var(--height);
		background-color: #999999;
		border-radius: 100%;
		overflow: hidden;
	}
	
	.circle:hover {
		overflow: visible;
		cursor: pointer;
	}
	
	.circle:hover ~ .text p {
		transition: opacity .4s ease .6s;
		opacity: 1;
	}
	.circle:hover ~ .text h5 {
		transition: color .4s ease .6s;
		color: #58595B;
	}
	
	.adam-img {
		width: calc(var(--width) * 3); /* 3x bigger than .circle width */
		height: calc(var(--height) * 3); /* 3x bigger than .circle height */
		transform: translate(-59.5%, -12%);
		//width: 1260px; /* 3x bigger than .circle width */
		//height: auto;
		//transform: translate(-59.5%, 3%);
	}
	
	.circle:hover .adam-img {
		transition: all 0.6s ease;
		transform: translate(-32%, -30%) scale(0.4);
	}
	
	
	/* mona */
	
	.mona-img {
		width: calc(var(--width) * 2); /* 2x bigger than .circle width */
		height: calc(var(--height) * 2); /* 2x bigger than .circle height */
		transform: translate(-20%, -58%);
	}
	
	.circle:hover .mona-img {
		transition: all 0.6s ease;
		transform: translate(-25%, -25%) scale(0.52, 0.45);
	}
	
	/* cotton */
	
	.cotton-img {
		width: calc(var(--width) * 3); /* 3x bigger than .circle width */
		height: calc(var(--height) * 3); /* 3x bigger than .circle height */
		transform: translate(-30.5%, -15.75%);
	}
	
	.circle:hover .cotton-img {
		@include media-breakpoint-up(md) {
			animation: cotton-to-right 0.6s;
		}
		transition: all 0.6s ease;
		transform: translate(-32%, -32%) scale(0.4);
	}
}

@keyframes cotton-to-right {
	0% {
		transform: translateX(10%);
		-moz-transform: translateX(10%);
	}
}

.section-effects {
	.swiper-pagination {
		position: relative;
		margin-top: 25px;
		margin-bottom: 0;
		
		.swiper-pagination-bullet {
			width: 13px;
			height: 13px;
			margin: 0 9px;
			border: none;
			background-color: #333333;
			
			&.swiper-pagination-bullet-active {
				background-color: #FFAB00;
				transform: scale(1);
			}
		}
	}
	
	.text-wrapper {
		display: flex;
		justify-content: space-between;
		padding: 25px 20px;
		
		p {
			margin-bottom: 0;
		}
		
		a {
			color: $primary;
			display: flex;
			align-items: center;
			
			.icon {
				margin-left: 5px;
				font-size: 8px;
				transition: margin-left .4s;
			}
			
			&:hover {
				.icon {
					margin-left: 8px;
				}
			}
		}
	}
	
	.effects-image-wrapper {
		position: relative;
		padding-bottom: 42.5%;
		
		.effects-image-bg {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			background-size: cover;
			background-position: center center;
		}
	}
}

@include media-breakpoint-down(xl) {
	.footer h2, .footer h2 {
		font-size: 80px;
		line-height: 80px;
	}
	.footer::before {
		top: 85px;
	}
	.section-hero .container {
		h1, .h1 {
			font-size: 80px;
		}

		img {
			max-width: 300px;
		}
	}
}

@include media-breakpoint-down(lg) {
	.section-360 {

	}
	.section-effects {
		.effects-image-wrapper {
			.effects-image {
				box-shadow: none;
			}
		}
	}
	.section-effects .effects-container-inner {
		margin-top: 0;
	}
	.footer h2, .footer h2 {
		font-size: 65px;
		line-height: 75px;
	}
	.footer::before {
		top: 75px;
	}
	.section-hero .container {
		h1, .h1 {
			font-size: 65px;
		}
		img {
			max-width: 280px;
		}
	}
}

@include media-breakpoint-down(md) {
	.section-360 {
		margin-bottom: 50px;

		.section-title {
			margin-bottom: 50px;
		}

		.circle {
			--width: 40vw;
			--height: 40vw;
		}
	}
	.section-effects {
		margin-bottom: 0;

		.effects-text-wrapper .effects-text {
			.effects-redirect {
				margin-top: 40px;
				display: block;
				text-align: right;
			}
		}

		.effects-container-inner {
			margin-top: 0;
			padding-top: 20px;
			background: none;
		}

		.effects-image-wrapper {
			.effects-image {
				box-shadow: none;
			}
		}
	}
	.footer h2, .footer h2 {
		font-size: 55px;
		line-height: 65px;
	}
	.footer::before {
		top: 65px;
	}
	.section-hero .container {
		h1, .h1 {
			font-size: 55px;
		}
		img {
			max-width: 200px;
		}
	}
}

@include media-breakpoint-down(sm) {
	.section-360 {
		margin-bottom: 50px;

		.section-title {
			margin-bottom: 50px;
		}

		.circle {
			--width: 60vw;
			--height: 60vw;
		}
		.text p {
			font-size: 16px;
			line-height: 20px;
		}
	}
	.footer h2, .footer h2 {
		font-size: 40px;
		line-height: 50px;
	}
	.footer::before {
		top: 50px;
	}
	.section-hero .container {
		h1, .h1 {
			font-size: 40px;
		}

		img {
			max-width: 160px;
		}
	}
}

@include media-breakpoint-down(xs) {
	.footer h2, .footer h2 {
		font-size: 30px;
		line-height: 37px;
	}
	.footer::before {
		top: 37px;
	}
	.section-hero {
		.container {
			h1, .h1 {
				font-size: 30px;
				padding-right: 20px;
				margin-bottom: 10px;
			}
			
			img {
				max-width: 125px;
				padding-right: 20px;
			}
		}
	}
}


