// 
// layout: page
//

body{
    overflow-x: hidden;
}

body.page-home {
    .page {
        margin-top: 0;

        &:after {
            display: none;
        }
    }
}

.page{
    overflow: hidden;
    margin-top: 142px;
    position: relative;
}

.page-content{
    position: relative;
    z-index: 2;
}


@include media-breakpoint-down(xl) { }

@include media-breakpoint-down(lg) {
    .page{
        &:after {
            display: none;
        }
    }
}

@include media-breakpoint-down(md) { }

@include media-breakpoint-down(xs) {
    .page{
        margin-top: 72px;
    }
}






