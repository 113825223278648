

[data-aos="translate-to-top"] {
    position: relative;
    z-index: 44;
    opacity: 0;
    transform: translate(0px,60px);
    &.aos-animate {
        opacity: 1;
        transform: translate(0px,0px);
    }
}

@include media-breakpoint-up(lg) {
    [data-aos="portfolio-anim"] {
        position: relative;
        z-index: 44;
        opacity: 0;
        transform: translate(0px, 60px);

        &.aos-animate {
            opacity: 1;
            transform: translate(0px, 0px);
        }
    }
}

@include media-breakpoint-down(md) {
    [data-aos="portfolio-anim"] {
        .circle {
            .adam-img {
                width: calc(var(--width) * 3); /* 3x bigger than .circle width */
                height: calc(var(--height) * 3); /* 3x bigger than .circle height */
                transform: translate(-59.5%, -12%);
            }

            .mona-img {
                width: calc(var(--width) * 2); /* 2x bigger than .circle width */
                height: calc(var(--height) * 2); /* 2x bigger than .circle height */
                transform: translate(-20%, -58%);
            }

            .cotton-img {
                width: calc(var(--width) * 3); /* 3x bigger than .circle width */
                height: calc(var(--height) * 3); /* 3x bigger than .circle height */
                transform: translate(-30.5%, -15.75%);
            }
        }

        &.aos-animate {
            .circle {
                overflow: visible;
                .adam-img {
                    transition: all 0.6s ease;
                    transform: translate(-32%, -30%) scale(0.4);
                }

                .mona-img {
                    transition: all 0.6s ease;
                    transform: translate(-25%, -25%) scale(0.52, 0.45);
                }

                .cotton-img {
                    //animation: cotton-to-right 0.6s;
                    transition: all 0.6s ease;
                    transform: translate(-32%, -32%) scale(0.4);
                }
            }
            .text p {
                transition: opacity .4s ease .6s;
                opacity: 1;
            }

            .text h5 {
                transition: color .4s ease .6s;
                color: #58595B;
            }
        }
    }
}

[data-aos="translate-to-right"] {
    opacity: 0;
    transform: translate(60px,0px);
    transition: all 0.45s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    &.aos-animate {
        opacity: 1;
        transform: translate(0,0);
    }
}
[data-aos="translate-to-left"] {
    opacity: 0;
    transform: translate(-60px,0px);
    transition: all 0.45s cubic-bezier(0.895, 0.03, 0.685, 0.22);
    &.aos-animate {
        opacity: 1;
        transform: translate(0,0);
    }
}


[data-aos="image-block-to-right"] {
    position: relative;
    overflow: hidden;
    img{
        opacity: 0;
        transform: translateX(-110%);
        transition: all 1.15s;
    }
    &.aos-animate {
        img{
            transform: translateX(0%);
            opacity: 1;
        }
    }
}

[data-aos="image-block-to-right-sm"] {
    position: relative;
    overflow: hidden;
    img{
        opacity: 0;
        transform: translateX(-110%);
        transition: all 0.65s;
    }
    &.aos-animate {
        img{
            transform: translateX(0%);
            opacity: 1;
        }
    }
}

[data-aos="image-block-to-top"] {
    position: relative;
    overflow: hidden;
    img{
        opacity: 0;
        transform: translateY(-110%);
        transition: all 0.75s;
    }
    &.aos-animate {
        img{
            transform: translateY(0%);
            opacity: 1;
        }
    }
}

[data-aos="section"] {
    .section-title {
        &:before {
            transition: all 0.7s;
            left: -100%;
        }
        h5 {
            transition: all 0.5s 0.7s;
            opacity: 0;
        }
    }

    &.aos-animate {
        .section-title {
            &:before {
                left: calc(-90px / 2);
            }
            h5 {
                opacity: 1;
            }
        }
    }
}
