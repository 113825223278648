//
// Cookie
//
.cookie {
    position: fixed;
    z-index: 9999;
    left: 0;
    right: 0;
    bottom: -100%;
    padding: 10px 15px;
    padding-top: 20px;
    background-color: $primary;
    transition: all 0.5s;
    overflow: hidden;
    opacity: 0;
    &-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &-img-wrapper {
        flex: 0 0 100px;
        max-width: 100px;
    }
    &-text-wrapper {
        flex: 1 0 auto;
        margin-left: 10px;
        text-align: center;
        max-width: 300px;

        p {
            font-size: 16px;
            color: $white;
            margin: 0;
        }
    }
    &-btn-wrapper {
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
        .btn {
            font-size: 20px;
            font-weight: bold;
            color: $white;
            padding: 0;
            border: none;

            &:active:not(:disabled):not(.disabled),
            &:hover:not(:disabled):not(.disabled),
            &:focus:not(:disabled):not(.disabled),
            &:active:not(:disabled):not(.disabled) {
                color: $black;
            }
        }
    }
    &.show {
        bottom: 0;
        opacity: 1;
    }
}

@include media-breakpoint-down(xl) {
}

@include media-breakpoint-down(sm) {
    .cookie {
        .cookie-text-wrapper {
            max-width: 250px;
        }
        p {
            font-size: 13px;
        }
        .btn {
            font-size: 16px;
        }
    }

}
@include media-breakpoint-down(xs) {
    .cookie {
        .cookie-text-wrapper {
            max-width: 240px;
        }
        .cookie-img-wrapper {
            flex: 0 0 75px;
            max-width: 75px;
        }
        p {
            font-size: 11px;
        }
        .btn {
            font-size: 12px;
        }
    }

}