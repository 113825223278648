// 
// layout: footer
//

.page-scroll {
    .header .header-brand img {
        opacity: 0;
    }
}

.header{
    position: fixed;
    z-index: 999;
    left: 0;
    right: 0;
    top: 0;
    transition: all 0.3s;

    .header-row {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 25px;
    }
    .header-brand{
        position: absolute;
        left: 25px;
        top: 25px;
        img{
            display: block;
            position: relative;
            z-index: 2;
            opacity: 1;
            transition: opacity .4s;
        }
    }
    .header-right {
        display: flex;
        justify-content: flex-end;


        ul.header-menu{
            display: flex;
            align-self: center;
            margin: 0;
            padding: 0;
            li {
                a {
                    color: #575756;
                    font-size: 14px;
                    line-height: 18px;
                    padding-left: 24px;
                    padding-right: 24px;
                    display: block;
                    position: relative;
                    font-weight: $font-weight-regular;
        
                    &:hover, &.active {
                        color: $primary;
                    }
    
                }
    
            }
        }
        ul.header-social {
            display: flex;
            align-self: center;
            margin: 0;
            padding: 0;
            margin-left: -10px;
            li {
                a {
                    margin-left: 15px;
                    border-radius: 100%;
                    background-color: #575756;
                    width: 30px;
                    height: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    .icon {
                        display: block;
                        font-size: 20px;
                        color: $white;
                        transition: color .4s;
                    }

                    &:hover {
                        background-color: $primary;
                    }
                }
            }
        }
    }


    .header-toggler{
        display: none;
        font-size: 80px;
        line-height: 0;
        letter-spacing: 23px;
        margin-right: -12px;
        cursor: pointer;
        color: $primary;
    }
}


@include media-breakpoint-down(xl) {
    .header {

    }
}
@include media-breakpoint-down(xl) {
    .header {

    }
}
@include media-breakpoint-down(lg) {

    .header{

    }
}

@include media-breakpoint-down(md) {
    .header{
        .header-brand{
            left: 25px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .header {
        .header-brand{
            left: 25px;
        }
        .header-right {
            ul.header-menu li a {
                padding-right: 12px;
                padding-left: 12px;
            }
        }
    }
}
@include media-breakpoint-down(xs) {
    .header{
        .header-brand{
            top: 15px;
            left: 15px;
            max-width: 50px;
        }
        .header-row {
            padding-left: 15px;
            padding-right: 15px;
        }
        .header-right {
            ul.header-social {
                display: none;
            }

            ul.header-menu {
                display: none;
            }
            .header-toggler {
                display: block;
            }
           /* ul.header-menu li a{
                padding-right: 8px;
                padding-left: 8px;
            }*/
        }
    }
}

