@charset 'utf-8';

/*!
 * Bootstrap v4.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2018 The Bootstrap Authors
 * Copyright 2011-2018 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

 @import "functions";
 @import "variables";
 @import "settings";
 @import "mixins";
 @import "root";
 @import "reboot";
 @import "type";
 @import "images";
 @import "code";
 @import "grid";
 @import "tables";
 @import "forms";
 @import "buttons";
 @import "transitions";
 @import "dropdown";
 @import "button-group";
 @import "input-group";
 @import "custom-forms";
 @import "nav";
 @import "navbar";
 @import "card";
 @import "breadcrumb";
 @import "pagination";
 @import "badge";
 @import "jumbotron";
 @import "alert";
 @import "progress";
 @import "media";
 @import "list-group";
 @import "close";
 @import "modal";
 @import "tooltip";
 @import "popover";
 @import "carousel";
 @import "utilities";
 @import "print";


// /*!
//  * Project:	      Deoplaza XY
//  * Version:	      1.0
//  * Assigned to:    Comup.pl
// */

// 1. Abstracts
@import "abstracts/variables";
@import "abstracts/sass-rem";
@import "abstracts/mixins";
@import "abstracts/functions";

// 2. Utilities

// 3. Vendors
@import "vendors/aos/aos";
@import "vendors/mfp/main";
@import "vendors/swiper/swiper";
@import "vendors/hamburgers/hamburgers";
@import "vendors/bootstrap-select/bootstrap-select";
@import "vendors/simplebar/simplebar";

// 4. Base
@import "base/base";
@import "base/typography";
@import "base/list";
@import "base/icons";
@import "base/animations";

// 5. Components
@import "components/buttons";
@import "components/bredcrumbs";
@import "components/image-holder";
@import "components/swiper";
@import "components/mfp";
@import "components/cookie";
@import "components/modal";

// 6. Layout
@import "layout/page";
@import "layout/header";
@import "layout/offcanvas";
@import "layout/article";
@import "layout/footer";
@import "layout/section";

// 7. Pages
@import "pages/home";
@import "pages/404";
@import "pages/portfolio";

@import "base/aos";

// 8. Themes
@import "themes/default";





