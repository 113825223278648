
.section{
    position: relative;
    overflow: hidden;
}

.section-relative{
    position: relative;
}

.section-title {
    position: relative;
    padding-left: 75px;
    padding-top: 30px;
    padding-bottom: 30px;
    h5, .h5 {
        position: relative;
        z-index: 5;
    }
    &:before {
        z-index: 4;
        content: '';
        height: 90px;
        width: 90px;
        background-color: $primary;
        border-radius: 100%;
        position: absolute;
        left: calc(-90px / 2);
        top: 50%;
        margin-top: calc(-90px / 2);
    }
}


@include media-breakpoint-down(xl) {

}

@include media-breakpoint-down(lg) {

}

@include media-breakpoint-down(md) {


}


@include media-breakpoint-down(sm) {

}
@include media-breakpoint-down(xs) {
    .section-title {
        padding-left: 60px;
        padding-top: 50px;
        padding-bottom: 50px;
        &:before {
            height: 100px;
            width: 100px;
            left: -50px;
            margin-top: -50px;

        }
    }
}



